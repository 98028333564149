import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { set } from 'lodash'

import { getEnv } from 'helpers/env-config-helper'
import { getToken } from 'services/auth/auth-service'

import { BaseHttpClient, ContentType, getContentTypeHeader } from './base-http-client'

class EncoClient extends BaseHttpClient {
  constructor() {
    super({
      baseURL: getEnv('encoApiHost'),
    })
  }

  protected onRequest(request: AxiosRequestConfig<any>): AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>> {
    const token = getToken()
    if (token) set(request, 'headers.common.Authorization', `Bearer ${token}`)
    return request
  }

  post<T>(url: string, data?: any, config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T, any>> {
    return super.post<T>(url, data, { headers: getContentTypeHeader(ContentType.FORM_DATA), ...config })
  }

  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
    return super.put<T>(url, data, { headers: getContentTypeHeader(ContentType.FORM_DATA), ...config })
  }

  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
    return super.patch<T>(url, data, { headers: getContentTypeHeader(ContentType.FORM_DATA), ...config })
  }

  delete<T>(url: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<T, any>> {
    return super.delete<T>(url, { headers: getContentTypeHeader(ContentType.FORM_DATA), ...config })
  }

  protected onResponse(response: AxiosRequestConfig<any>): AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>> {
    if (response.data?.status?.isError && response.data?.status?.message !== 'NoData') {
      return Promise.reject(response.data.status.message)
    }
    return response
  }
}

export const encoClient = new EncoClient()
