import { Button, Card, Col, Divider, Form, notification, Row, Space, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { TextArea, Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { numberFormat } from 'helpers/number-helper'
import { mediaQuery } from 'helpers/responsive-helper'
import { backendPaths } from 'paths/backend-path-config'
import { ENCO_TYPE_OZONE_ID } from 'services/enco-service/enco-service-service'
import { ICancelOrderParams } from 'services/order-response/order-response-service-param'
import { IOrderItem, ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { useCancelOrderMTT } from 'services/order-response/order-response-service-service'

import { FieldsOzoneCustomerInfo } from './FieldsOzoneCustomerInfo'
import { TextOrderStatus } from './TextOrderStatus'
import { ButtonFullReceipt } from './full-receipt/ButtonFullReceipt'

const TotalCol = styled(Col)`
  white-space: nowrap;
  text-align: right;
  @media ${mediaQuery.xsOnly} {
    text-align: left;
  }
`

interface IFormOrderCancelProps {
  order: ISearchOrderResponse
  readonly?: boolean
}

export const FormOrderCancel = (props: IFormOrderCancelProps) => {
  const { order, readonly = false } = props
  const [form] = Form.useForm()
  const isOrderCanceled = useMemo(() => !!order.canceldate, [order])
  const [isCancelBtnClicked, setIsCancelBtnClicked] = useState(false)
  const modalConfirm = useModalConfirm()
  const { mutateAsync: cancelOrder } = useCancelOrderMTT()

  const handleClickCancel = useCallback(() => {
    setIsCancelBtnClicked(!isCancelBtnClicked)
  }, [isCancelBtnClicked])

  const handleFinish = useCallback(
    (values: ICancelOrderParams) => {
      modalConfirm.show({
        type: 'danger',
        title: 'ยืนยันการยกเลิกการจอง',
        content: `คุณยืนยันที่จะยกเลิกการจอง Order - ${order.orderNo} นี้หรือไม่?`,
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          const { rejectStaffDes, rejectUserDes } = values
          await cancelOrder({ id: order.id, rejectStaffDes, rejectUserDes })
          notification.success({ message: 'สำเร็จ', description: 'ยกเลิกการจองสำเร็จ', duration: 2 })
        },
      })
    },
    [cancelOrder, modalConfirm, order.id, order.orderNo],
  )

  const columns = useMemo(
    (): ColumnType<IOrderItem>[] => [
      {
        title: 'ลำดับ',
        dataIndex: 'recno',
        align: 'center',
        width: 50,
      },
      {
        title: 'รายการ',
        key: 'desc',
        render: (_: any, record: IOrderItem) => {
          let desc = record.itemnm
          if (!isEmpty(record.slotStart) && isEmpty(record.slotEnd))
            desc = `${desc} ช่วงเวลา ${record.slotStart} - ${record.slotEnd}`
          return <Txt ag="body20">{desc}</Txt>
        },
      },
      {
        title: 'หน่วย',
        dataIndex: 'unit',
        width: 150,
      },
      {
        title: 'ราคาต่อหน่วย',
        key: 'unitPrice',
        align: 'right',
        width: 150,
        render: (_: any, record: IOrderItem) => {
          return <Txt ag="body20">{numberFormat(record.unitPrice)}</Txt>
        },
      },
      {
        title: 'จำนวน',
        dataIndex: 'qty',
        align: 'center',
        width: 100,
      },
      {
        title: 'ราคาต่อรวม',
        key: 'totalPrice',
        align: 'right',
        width: 200,
        render: (_: any, record: IOrderItem) => (
          <Txt ag="body20">{numberFormat(Number(record.unitPrice) * Number(record.qty))}</Txt>
        ),
      },
    ],
    [],
  )

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleFinish}
      onFinishFailed={notifyFormValidateFailed}
      initialValues={order}
      scrollToFirstError
    >
      <Card>
        <Row gutter={24}>
          <Col span={24} sm={{ span: 12 }} className="text-left">
            <Txt ag="body20">สถานะการชำระเงิน</Txt>
          </Col>
          <Col span={24} sm={{ span: 12 }} className="text-right">
            <ButtonFullReceipt order={order} />
          </Col>
          <Col span={24} style={{ marginBottom: '16px' }}>
            <TextOrderStatus order={order} />
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="usernm" label="ผู้บันทึก Order ">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="customernm" label="ข้อมูลลูกค้า">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="receiveDatenm" label="วันและเวลาที่ชำระเงิน">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="receiptNo" label="หมายเลขใบเสร็จ">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Form.Item name="orderDateTimeNm" label="Order Date">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Form.Item name="orderNo" label="หมายเลขออเดอร์">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Form.Item name="orderTypeNm" label="ประเภทออเดอร์">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="serviceNm" label="ชื่อบริการ (Service Name)">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="servicePropNm" label="ชื่อบริการ Property">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: '32px 0 56px 0' }} />
        {order.tbServiceId === ENCO_TYPE_OZONE_ID && (
          <>
            <FieldsOzoneCustomerInfo order={order} />
            <Divider style={{ margin: '32px 0 56px 0' }} />
          </>
        )}
        {!readonly && (
          <>
            <Row gutter={24} style={{ marginBottom: '16px' }}>
              <Col span={24} md={{ span: 12 }}>
                <Txt ag="header30">สาเหตุการยกเลิกการจอง</Txt>
              </Col>
              <Col span={24} md={{ span: 12 }} className="text-right">
                <Button danger disabled={isOrderCanceled || isCancelBtnClicked} onClick={handleClickCancel}>
                  ยกเลิกการจอง
                </Button>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} sm={{ span: 12 }}>
                <Form.Item
                  name="rejectUserDes"
                  label="สาเหตุการยกเลิก (User)"
                  style={{ marginBottom: '8px' }}
                  required
                  rules={REQUIRED_RULES}
                >
                  <TextArea autoSize={{ minRows: 4, maxRows: 10 }} disabled={isOrderCanceled || !isCancelBtnClicked} />
                </Form.Item>
              </Col>
              <Col span={24} sm={{ span: 12 }}>
                <Form.Item
                  name="rejectStaffDes"
                  label="สาเหตุการยกเลิก (Staff)"
                  style={{ marginBottom: '8px' }}
                  required
                  rules={REQUIRED_RULES}
                >
                  <TextArea autoSize={{ minRows: 4, maxRows: 10 }} disabled={isOrderCanceled || !isCancelBtnClicked} />
                </Form.Item>
              </Col>
            </Row>
            {order.canceldateNm && (
              <Row>
                <Col span={24} className="text-left">
                  <Txt ag="body20" color="grayBorder">{`วันที่ยกเลิก ${order.canceldateNm} ${order.cancelStfNm}`}</Txt>
                </Col>
              </Row>
            )}
            <Divider style={{ margin: '32px 0 56px 0' }} />
          </>
        )}
        <Row>
          <Col span={24}>
            <Txt ag="header30">สรุปรายการคำสั่งซื้อ</Txt>
          </Col>
          <Col span={24}>
            <Table rowKey="id" dataSource={order.orderItem} columns={columns} pagination={false} />
          </Col>
        </Row>
        <Row style={{ marginTop: '32px' }}>
          <Col span={24} md={{ span: 12, offset: 12 }}>
            <Row>
              <Col span={16} offset={4}>
                <Txt ag="body20">ราคาไม่รวมภาษีมูลค่าเพิ่ม (Excluding VAT)</Txt>
              </Col>
              <Col span={4} className="text-right">
                <Txt ag="body20">{`${numberFormat(order.total)} บาท`}</Txt>
              </Col>
            </Row>
            {!!order.discount && (
              <Row>
                <Col span={16} offset={4}>
                  <Txt ag="body20">ส่วนลด (Discount)</Txt>
                </Col>
                <Col span={4} className="text-right">
                  <Txt ag="body20">{`${numberFormat(order.discount)} บาท`}</Txt>
                </Col>
              </Row>
            )}
            <Divider />
            <Row>
              <Col xs={24} sm={8} offset={4}>
                <Txt ag="header30">รวมสุทธิ (Total)</Txt>
              </Col>
              <TotalCol xs={{ span: 20, offset: 4 }} sm={{ span: 12, offset: 0 }}>
                <Txt ag="header30">{`${numberFormat(order.net)} บาท`}</Txt>
              </TotalCol>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Card className="w-100">
            <Space style={{ justifyContent: 'space-between', width: '100%' }}>
              <Link
                to={backendPaths.serviceOrderList({
                  routeParam: {
                    serviceId: Number(order.tbServiceId),
                  },
                })}
              >
                <Button htmlType="reset">กลับ</Button>
              </Link>
              {!readonly && (
                <Button disabled={isOrderCanceled || !isCancelBtnClicked} type="primary" htmlType="submit">
                  บันทึก
                </Button>
              )}
            </Space>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}
