import dayjs, { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { chain, compact } from 'lodash'
import { useState, useMemo } from 'react'
import { Event } from 'react-big-calendar'

import { AppBigCalendar } from 'components/AppBigCalendar'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { useGetOrderCalendarDisplayQRY } from 'services/order-response/order-response-service-service'

import type { BadgeProps } from 'antd'

const Container = styled.div``

interface IOrderCalendarEvent extends Event {
  id: number
  badge?: BadgeProps['status']
}

interface IOrderCalendarProps {
  serviceId: number
  selectedValue: Dayjs | null
  onSelected: (date: Dayjs | null) => void
}

export const OrderCalendar = (props: IOrderCalendarProps) => {
  const [filter, setFilter] = useState<Dayjs>(dayjs())

  const { data: service } = useGetServiceQRY(props.serviceId)
  const { mdServiceCatId = 0 } = service ?? {}
  const isKiosk = +mdServiceCatId === 3

  const { data: orders } = useGetOrderCalendarDisplayQRY({
    month: (filter?.month() || 0) + 1,
    year: filter?.year(),
    serviceId: props.serviceId,
  })

  const events = useMemo<IOrderCalendarEvent[]>(() => {
    const results = chain(orders ?? [])
      .transform((acc: IOrderCalendarEvent[], order) => {
        const { tborderId, serviceProperties, userDisplay, bookingDate, startTime, endTime } = order

        const bookingDateOnly = bookingDate.split('T')[0]
        const bookingStart = dayjs(compact([bookingDateOnly, startTime || '08:00']).join('T'), {
          locale: 'Asia/Bangkok',
        }).toDate()
        const bookingEnd = dayjs(compact([bookingDateOnly, endTime || '15:00']).join('T'), {
          locale: 'Asia/Bangkok',
        }).toDate()

        const titleList: string[] = []
        if (isKiosk) {
          titleList.push(serviceProperties, userDisplay)
        } else {
          titleList.push(compact([startTime, endTime]).join(' - '), userDisplay)
        }

        acc.push({
          id: tborderId,
          title: compact(titleList).join(' '),
          start: bookingStart,
          end: bookingEnd,
          allDay: false,
        })
        return acc
      }, [])
      .value()
    return results
  }, [isKiosk, orders])

  return (
    <Container>
      <AppBigCalendar<IOrderCalendarEvent>
        events={events}
        onNavigate={(newDate, view, action) => {
          setFilter(dayjs(newDate))
          props.onSelected(null)
        }}
        onSelectEvent={(d) => {
          props.onSelected(dayjs(d.start))
        }}
        style={{
          height: '600px',
        }}
        hideAgendaTime={isKiosk}
        min={isKiosk ? dayjs().hour(8).startOf('hour').toDate() : undefined}
        max={isKiosk ? dayjs().hour(15).startOf('hour').toDate() : undefined}
        step={60}
        timeslots={1}
        showMultiDayTimes
        doShowMoreDrillDown
        popup
      />
    </Container>
  )
}
