import dayjs from 'dayjs'
import { styled, css } from 'goober'
import { useMemo } from 'react'
import { Calendar as BaseCalendar, CalendarProps, dayjsLocalizer, Event } from 'react-big-calendar'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const hideAgendaTimeCss = css`
  .rbc-agenda-view {
    .rbc-agenda-table > thead > tr > *:nth-child(2) {
      display: none;
    }

    .rbc-agenda-content > .rbc-agenda-table > tbody > tr > *.rbc-agenda-time-cell {
      display: none;
    }
  }
`

const WrapStyled = styled.div`
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    border-left: 1px solid #ddd;
  }
`

const localizer = dayjsLocalizer(dayjs)
localizer.formats.timeGutterFormat = 'HH:mm'

interface AppBigCalendarProps<TEvent extends object = Event, TResource extends object = object>
  extends Omit<CalendarProps<TEvent, TResource>, 'localizer'> {
  hideAgendaTime?: boolean
}

export const AppBigCalendar = <TEvent extends object = Event, TResource extends object = object>(
  props: AppBigCalendarProps<TEvent, TResource>,
) => {
  const { hideAgendaTime, ...restProps } = props
  const classNames = useMemo(() => {
    const temp: string[] = []

    if (hideAgendaTime) {
      temp.push(hideAgendaTimeCss)
    }

    return temp.join(' ') || undefined
  }, [hideAgendaTime])

  return (
    <WrapStyled className={classNames}>
      <BaseCalendar localizer={localizer} {...restProps} />
    </WrapStyled>
  )
}
