import { notification } from 'antd'
import { Rule } from 'antd/lib/form'
import { chain, get } from 'lodash'

export const notifyFormValidateFailed = () =>
  notification.error({
    message: 'ผิดพลาด',
    description: 'ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ',
  })

export const ruleStringRequired: Rule = {
  type: 'string',
  required: true,
  message: 'โปรดกรอก${label}',
}


export const makeFieldValidator = <T extends (v: any) => Promise<void>>(cb: T): Rule => {
  return ({ getFieldsValue }) => {
    const values = getFieldsValue()
    return {
      validator: () => {
        return cb(values)
      },
    }
  }
}

export const makeValidateError = <T, IDK extends keyof T, K extends keyof T, P extends Pick<T, K>>(
  idKey: IDK,
  keys: K[],
  data: T[] = [],
  validateText?: string, // custom validate text
): ((values: P) => Promise<void>) => {
  return (values: P) => {
    for (const d of data ?? []) {
      const id1 = get(d, idKey)
      const id2 = get(values, idKey)
      const isNotSameId = id1 !== id2
      const isAllMatched = checkMatched<T, K, P>(keys, d, values)
      if (isAllMatched && isNotSameId) return Promise.reject(validateText ?? '${label} is already exists')
    }

    return Promise.resolve()
  }
}

const stringMatched = (v1: string, v2: string): boolean => {
  if (!v1 || !v2) return false
  return v1.toLowerCase().trim() === v2.toLowerCase().trim()
}


const checkMatched = <T, K extends keyof T, P extends Pick<T, K>>(keys: K[], d: T, values: P) => {
  return chain(keys)
    .every((k) => {
      const v1 = d[k]
      const v2 = values[k]
      if (!v1 && !v2) {
        return true
      }
      if (!v1 || !v2) {
        return false
      }
      return stringMatched(`${v1}`, `${v2}`)
    })
    .value()
}
