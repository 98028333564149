import { Card } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { chain, set } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { BackButton } from 'components/BackButton'
import { numberFormat } from 'helpers/number-helper'
import { backendPaths } from 'paths/backend-path-config'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import { IGetReportParams } from 'services/report/report-params'
import { INewReportDataResponse, INewReportGroupItem } from 'services/report/report-response'
import { useExportReportMTT, useGetReportNewQRY } from 'services/report/report-service'

import { FilterExportSection } from './ReportCommon'
import { IPageReportQueryParams } from './ReportFilterModal'

const isNumberBlank = (value?: string | null) => {
  if (!value) return undefined
  return Number(value)
}
const isDateBlank = (value?: string | null) => {
  if (!value) return undefined
  return dayjs(value)
}

type DataType = Omit<INewReportGroupItem, 'mergeGrp'> & {
  'รวม ชั่วโมง/เดือน': number
  'รวม บาท/เดือน (ไม่รวม VAT)': number
  rowSpan: number
}

const onCell = (e: DataType) => ({
  rowSpan: e.rowSpan,
})

export const ReportEncoStadiumSponsor = () => {
  const { reportId: reportIdParam } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const reportId = Number(reportIdParam)

  const serviceId = useMemo(() => isNumberBlank(searchParams.get('serviceId')), [searchParams])
  const startDate = useMemo(() => isDateBlank(searchParams.get('startDate')), [searchParams])
  const endDate = useMemo(() => isDateBlank(searchParams.get('endDate')), [searchParams])
  const year = useMemo(() => searchParams.get('year'), [searchParams])
  const month = useMemo(() => searchParams.get('month'), [searchParams])
  const receiptTypeId = useMemo(() => searchParams.get('receiptTypeId'), [searchParams])
  const packageType = useMemo(() => searchParams.get('package'), [searchParams])

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const filter = useMemo((): IGetReportParams => {
    let newFilter: IGetReportParams = { reportId }
    if (serviceId) newFilter.serviceId = serviceId
    if (startDate) newFilter.startDate = dayjs(startDate).format(apiDateFormat)
    if (endDate) newFilter.endDate = dayjs(endDate).format(apiDateFormat)
    if (year) newFilter.year = +year
    if (month) newFilter.month = +month
    if (receiptTypeId) newFilter.receiptTypeId = +receiptTypeId
    if (packageType) newFilter.package = packageType
    return newFilter
  }, [reportId, serviceId, startDate, endDate, year, month, receiptTypeId, packageType])

  const { data: report } = useGetReportNewQRY(filter)
  const { data: service } = useGetServicesQRY({ serviceType: GetServiceTypeEnum.ALL })
  const { mutateAsync: downloadExcel } = useExportReportMTT()

  const handleClickDownload = useCallback(() => {
    downloadExcel(filter)
  }, [downloadExcel, filter])

  const handleFilterChange = (values: IPageReportQueryParams) => {
    const params: Partial<Record<keyof IPageReportQueryParams, string>> = {}
    Object.entries(values).forEach(([k, v]) => {
      if (v) {
        set(params, k, v)
      }
    })
    setSearchParams(params)
  }

  const reportObject = useMemo((): INewReportDataResponse => {
    if (report?.reportData) return report.reportData
    return {
      TotalHours: 0,
      TotalValue: 0,
      Groups: [],
    }
  }, [report])

  console.log({ reportObject })

  const dataSource = useMemo((): DataType[] => {
    return chain(reportObject.Groups ?? [])
      .transform((acc: DataType[], group) => {
        // const itemLength = group.Items.length
        group.Items.forEach((item, i) => {
          let rowSpan = 1
          // if (itemLength > 1) {
          //   if (i === 0) rowSpan = itemLength
          //   else rowSpan = 0
          // }
          acc.push({
            ...item,
            'รวม ชั่วโมง/เดือน': group.TotalHours,
            'รวม บาท/เดือน (ไม่รวม VAT)': group.TotalValue,
            rowSpan,
          })
        })
        return acc
      }, [])
      .value()
  }, [reportObject.Groups])

  const columns = useMemo((): ColumnsType<DataType> => {
    return [
      {
        title: 'ลำดับ',
        dataIndex: 'ลำดับ',
        align: 'center',
      },
      {
        title: 'Code',
        dataIndex: 'Code',
        align: 'center',
      },
      {
        title: 'วันที่ทำรายการ',
        dataIndex: 'วันที่ทำรายการ',
        align: 'center',
      },
      {
        title: 'เวลาที่ทำรายการ',
        dataIndex: 'เวลาที่ทำรายการ',
        align: 'center',
      },
      {
        title: 'ชื่อ-นามสกุล',
        dataIndex: 'ชื่อ-นามสกุล',
        align: 'center',
      },
      {
        title: 'Order No.',
        dataIndex: 'orderNo',
        align: 'center',
      },
      {
        title: 'วันที่รับบริการ',
        dataIndex: 'วันที่รับบริการ',
        align: 'center',
      },
      {
        title: 'เวลาที่รับบริการ',
        dataIndex: 'เวลาที่รับบริการ',
        align: 'center',
      },
      {
        title: 'ชั่วโมง',
        dataIndex: 'ชั่วโมง',
        align: 'center',
      },
      {
        title: 'มูลค่าบาท/ชั่วโมง (ไม่รวม VAT)',
        dataIndex: 'มูลค่าบาท/ชั่วโมง (ไม่รวม VAT)',
        align: 'center',
        render: (v) => numberFormat(v, 2),
      },
      // {
      //   title: 'รวม ชั่วโมง/เดือน',
      //   dataIndex: 'รวม ชั่วโมง/เดือน',
      //   align: 'center',
      //   onCell,
      //   render: (v) => numberFormat(v, 2),
      // },
      {
        title: 'รวม บาท/เดือน (ไม่รวม VAT)',
        dataIndex: 'รวม บาท/เดือน (ไม่รวม VAT)',
        align: 'center',
        onCell,
        render: (v) => numberFormat(v, 2),
      },
      {
        title: 'หมายเหตุ',
        dataIndex: 'หมายเหตุ',
        align: 'center',
      },
    ]
  }, [])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const tableSummary = useCallback(() => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={8} align="center">
          <span style={{ fontWeight: 'bold' }}>รวม</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="center">
          <span style={{ fontWeight: 'bold' }}>{numberFormat(reportObject.TotalHours, 2)}</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={1} />

        <Table.Summary.Cell index={3} align="center">
          <span style={{ fontWeight: 'bold' }}>{numberFormat(reportObject.TotalValue, 2)}</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={1} />
      </Table.Summary.Row>
    )
  }, [reportObject.TotalHours, reportObject.TotalValue])

  if (!service) return null

  return (
    <>
      <ContentHeader title="Report" subTitle={report?.reportName} />
      <ContentBody>
        <FilterExportSection
          reportId={reportId}
          onDownload={handleClickDownload}
          services={service}
          initialValues={filter}
          onFilterChange={handleFilterChange}
        />
        <Card>
          <Table
            scroll={{ x: '100%' }}
            rowKey="ลำดับ"
            dataSource={dataSource}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              onChange: handlePageChange,
              current: currentPage,
            }}
            summary={tableSummary}
          />
          <BackButton to={backendPaths.reportList()} />
        </Card>
      </ContentBody>
    </>
  )
}
